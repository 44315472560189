.collapse-item {
    border: solid 1px $color_light_grey;
    border-radius: .4rem;
    & + & {
        margin-top: 2rem;
    }

    &__title {
        display: block;
        padding-top: 2rem;
        padding-bottom: 2rem;
        text-align: center;
        font-size: 1.4rem;
        text-transform: uppercase;
        color: inherit;
        cursor: pointer;
        position: relative;
        &.collapsed:after {
          transform: translateY(-50%) rotate(0);
        }
        &:after {
          transform-origin: 50% 50%;
          transition: transform 200ms ease;
          display: block;
          width: 1.4rem;
          height: .9rem;
          background-image: url(../img/check.svg);
          content: "";
          position: absolute;
          right: 1.6rem;
          top: 50%;
          transform: translateY(-50%) rotate(-180deg);
        }
    }
    &__body {
        padding: 0 1.6rem 4rem;
        color: $color_base;
    }
}

.collapse-opened {
    color: $color_link;
    border: 1px solid $color_link;
    .collapse__title:after {
        transform: translateY(-50%) rotate(180deg);
    }
    .collapse__content {
        height: auto;
    }
}

.collapse {
  display: none;
}
.collapse.in {
  display: block;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
  -webkit-transition-duration: .35s;
       -o-transition-duration: .35s;
          transition-duration: .35s;
  -webkit-transition-property: height, visibility;
       -o-transition-property: height, visibility;
          transition-property: height, visibility;
}