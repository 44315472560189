.postfix {
    text-align: center;
    &__title {
        font-size: 3.6rem;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
    }
    &__text {
        margin-bottom: 1rem;
        font-size: 2rem;
        margin-bottom: 0.9rem;
    }
    &__footer {
        color: $color_link;
        font-weight: bold;
        font-size: 3.6rem;
        line-height: 1.17;
    }
}