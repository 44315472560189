.menu {
    display: none;
    @media (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }
    li {
        padding-top: .4rem;
    }
    a {
        font-size: 1.4rem;
        letter-spacing: 1px;
        color: #d3d8e1;
        text-transform: uppercase;
    }
}

.menu-toggle {
    width: 2.8rem;
    height: 2.8rem;
    background-image: url('../img/mainmenu.svg');
    background-size: cover;
    margin-right: 2rem;
    cursor: pointer;
    @media (min-width: $screen-md-min) {
        display: none;
    }
}

.menu-adaptive {
    background-color: #25303b;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    @media (min-width: $screen-md-min) {
        display: none!important;
    }
    li {
        padding-top: 2rem;
        padding-bottom: 2rem;
        text-align: center;
    }
    li + li {
        border-top: 1px solid #3b4857;
    }
    a {
        font-size: 1.8rem;
        letter-spacing: 1px;
        color: #d3d8e1;
        text-transform: uppercase;
    }
}

.menu-adaptive-container {
    position: relative;
}