.account {
    background-color: $color_white;
    padding: 2rem;
    margin-bottom: 3rem;
    &__logo {
        font-size: 0;
        img {
            width: 10rem;
            height: 5.8rem;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__title {
        text-align: center;
        font-size: 1.6rem;
        text-transform: uppercase;
        color: $color_base;
        flex-grow: 1;
    }
    &__body {
        margin-top: 2rem;
        font-size: 1.2rem;
        color: $color_base;
    }
}

.account-group {
    margin-top: 4rem;
}