.footer {
    background-color: $color_grey;
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 1.2rem;
    line-height: 1.5;
    @media (min-width: $screen-sm) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.footer-logo {
    font-size: 0;
    img {
        height: 3rem;
    }
}

.footer-copyright {
    margin-top: .7rem;
}

.footer-email {
    font-weight: bold;
    margin-top: 2rem;
    a {
        text-decoration: none;
    }
}

.footer-phone {
    margin-top: .7rem;
}

.footer-address {
    margin-top: 3.7rem;
}

.footer-phone-list {
    margin-top: 2rem;
    &__item {
        & + & {
            margin-top: .5rem;
        }
    }
}

.footer-disclaimer {
    font-size: 1.1rem;
    line-height: 1.63;
    margin-top: 2.5rem;
    color: $color_light_grey;
}

.footer-links {
    margin-top: 2rem;
    display: flex;
    &__item {
        font-size: 1.1rem;
        & + & {
            margin-left: 20px;
        }
    }
}

.footer-menu {
    margin-bottom: 4rem;
    @media (min-width: $screen-sm) {
        margin-bottom: 0;
    }
    &__title {
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 1.3rem;
        margin-bottom: 2rem;
        border-bottom: 2px solid $color_link;
    }
    &__item {
        margin-bottom: .9rem;
        a {
            color: inherit;

        }
    }
}

.footer-social {
    margin-top: 2rem;
    display: flex;
    &__item {
        & + & {
            margin-left: .9rem;
        }
    }
}