.header {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
}

.header-register {
    font-size: 1.2rem;
    display: inline-block;
    margin-bottom: 1rem;
    @media (min-width: $screen-sm-min) {
        margin-bottom: 0;
    }
}

.header-button {
    border: 1px solid $color_link;
    padding: .6rem 1rem;
    border-radius: .4rem;
    font-size: 1.4rem;
    color: $color_link;
    margin-left: 1.5rem;
}

.header-buttons {
    flex-grow: 1;
    text-align: right;
    p {
        @media (min-width: $screen-sm-min) {
            display: inline-block;
        }
    }
}

.post-header {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #3b4857;
    display: none;
    @media (min-width: $screen-md-min) {
        display: block;
    }
    &__search {
        position: relative;
        input {
            padding: .6rem .6rem .6rem 3.2rem;
            display: block;
            border: none;
            border-radius: .4rem;
            background-color: $color_white;
            width: 100%;
            font-size: 1.8rem;
            @media (min-width: $screen-md-min) {
                font-size: 1.5rem;
            }
        }
        &:after {
            width: 1.6rem;
            height: 1.6rem;
            background-size: cover;
            background-image: url('../img/search-icon.svg');
            content: "";
            top: 50%;
            transform: translateY(-50%);
            left: .7rem;
            display: block;
            position: absolute;
        }
    }
}

.header-logo {
    width: 17.3rem;
    height: 5.4rem;
    display:flex;
    margin-right: 1rem;
    a {
        display: flex;
        align-items: center;
    }
    img {
        width: 100%;
    }
}