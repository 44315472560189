/**
* Tags styles
*/
.raw {
    h1, h2 {
        text-transform: uppercase;
        font-size: 3.6rem;
        line-height: 1.17;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
    }

    h3 {
        font-size: 2rem;
        line-height: 1.2;
        font-weight: normal;
        text-align: center;
        margin-bottom: 6rem;
    }
    h4 {
        font-size: 1.6rem;
        line-height: 1.25;
        font-weight: bold;
        margin-bottom: 2rem
    }
    h5 {
        margin-top: 4rem;
        font-size: 1.3rem;
        font-weight: bold;
    }
    p {
        font-size: 1.3rem;
        & + p {
            margin-top: 1rem;
        }
    }
    ul {
        li {
            font-size: .6rem;
            position: relative;
            font-size: 1.3rem;
            padding-left: 3.4rem;
            &:after {
                position: absolute;
                content: '';
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: $color_link;
                top: 0.8rem;
                left: 1rem;
            }
            & + li {
                margin-top: 1rem;
            }
        }
    }

    h5 + ul {
        margin-top: 1.6rem;
    }

    ul + p,
    ul + h4,
    ul + h5 {
        margin-top: 4rem;
    }
    hr {
        border: none;
        border-bottom: dashed 1px #a8b7c7;
        margin-top: 4rem;
        margin-bottom: 4rem;
        &:first-child {
            margin-top: 0;
        }
    }
}