.form {
    padding-top: 2rem;
}

.form-captcha {
    margin-bottom: 2rem;
}

.form-success,
.form-failed {
    display: block;
    width: 100%;
    padding: 1rem 2rem;
    border-radius: .8rem;
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1rem;
    display: none;
}

.form-success {
    background-color: green;
}

.form-failed {
    background-color: red;
}

.form-success,
.form-failed {
    a {
        border-bottom: 1px solid #ffffff;
    }
}

.form-error {
    position: absolute;
    top: 0;
    font-size: 1.2rem;
    background-color: red;
    color: #ffffff;
    padding: .5rem 1rem;
    border-radius: .5rem;
    z-index: 1;
    right: 0;
    display: none;
}

.form-invalid + .form-error {
    display: block;
}

.form-input,
.form-textarea {
    display: block;
    width: 100%;
    border: 1px solid $color_border_form;
    font-size: 1.3rem;
    color: $color_form;
    border-radius: .2rem;
    padding: 1.4rem 2rem;
    font-family: inherit;
    transition: all 200ms ease;
    outline: none;
    &:focus {
        box-shadow: 0 0 4px 0 rgba(0, 193, 159, 0.8);
        border: solid 1px #00c19f;
    }
}

.form-field {
    position: relative;
    & + & {
        margin-top: 2rem;
    }
    &.first {
        margin-top: 2rem;
        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }
    }
}

.form-hint {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.3rem;
}

.form-submit {
    margin-top: 4rem;
}

.form-radio {
    display: flex;
    align-items: center;
    [type="radio"] {
        display: none;
    }

    [type="radio"]:checked + i {
        background-image: url('../img/oval-5.svg');
    }

    i {
        background-size: cover;
        background-image: url('../img/oval-4.svg');
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        background-color: $color_white;
        border-radius: 50%;
        margin-right: 1.6rem;
    }
    span {
        font-size: 1.3rem;
        color: #25303b;
    }
}
