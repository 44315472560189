.tab-group {
    background-color: $color_base;
    padding-top: 6rem;
    &__title {
        text-align: center;
        color: $color_white;
        padding-bottom: 5rem;
    }   
}

.tab-button-group {
    @media (min-width: $screen-sm-min) {
        display: flex;
    }
    li.active {
        .tab-button {
            color: $color_link;
            @media (min-width: $screen-sm-min) {
                border-bottom: 4px solid $color_link;
            }
        }
    }
}

.tab-button {
    display: block;
    cursor: pointer;
    color: $color_border_form;
    span {
        display: block;
        margin-bottom: 1.5rem;
        padding: 0 9rem;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        @media (min-width: $screen-sm-min) {
            border-right: 1px dashed $color_border_form;
        }
    }
}

.tab-content {
    display: none;
    border-bottom: 1px solid $color_link;
    &.active {
        display: block;
    }
}