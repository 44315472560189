.contact {
    margin-top: 4rem;
    text-align: center;
    border: 1px solid $color_border_form;
    padding: 9rem 3rem 2rem;
    position: relative;
    &__address {
        line-height: 1.84;
        font-size: 1.3rem;
    }
    &__flag {
        position: absolute;
        top: 3rem;
        left: 50%;
        transform: translateX(-50%);
        width: 5.6rem;
        height: 4rem;
        background-size: contain;
        background-position: top center;
        &--gb {
            background-image: url('../img/gb.svg');
        }
        &--nl {
            background-image: url('../img/nl.svg');
        }
        &--us {
            background-image: url('../img/us.svg');
        }
        &--eu {
            background-image: url('../img/eu.svg');
        }
    }
}