.banks {
    padding-top: 4rem;
    padding-bottom: 5rem;
}

.bank {
    @media (min-width: $screen-md-min) {
        display: flex;
        align-items: stretch;
        text-align: left;
    }
    margin-bottom: 3rem;
    text-align: center;
    border: solid 1px rgba(0, 193, 159, 0.5);
    &--dark {
        border: solid 1px #dfe4ea;
    }
    &__title {
        font-size: 1.6rem;
        font-weight: bold;

    }
    &__body {
        font-size: 1.2rem;
        padding-top: 0.5rem;
    }
    &__content {
        padding: 1.7rem 2rem;
        flex-grow: 1;
    }
    &__more {
        font-size: 11px;
        text-decoration: underline;
        margin-top: 1rem;
        font-weight: bold;
    }
    &__image {
        img {
            width: 6.5rem;
            height: 6.5rem;
            border-radius: 50%;
            margin-top: 1.7rem;
            margin-left: 2rem;
        }
    }
    &__pricing {
        text-align: center;
        padding: 3.5rem;
        background-color: rgba(0, 193, 159, 0.1);
    }
}

.bank-start {
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    padding-top: 1.7rem;
    padding-bottom: 2.5rem;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__text {
        font-size: 1.1rem;
    }
    &__price {
        margin-left: 1.5rem;
        font-size: 2rem;
        color: $color_link;
        font-weight: bold;
    }
    &__button {
        margin-top: .7rem;
        color: #778593;
        border: 1px solid $color_light_grey;
        border-radius: .4rem;
        font-size: 1.2rem;
        display: block;
        text-align: center;
        padding-top: .7rem;
        padding-bottom: .7rem; 
    }
}

.bank-price {
    &__new {
        font-size: 2rem;
        color: $color_link;
        font-weight: bold;
    }
    &__old {
        font-size: 1.6rem;
        text-decoration: line-through;
    }
}