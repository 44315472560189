.promo-inner {
    background-size: cover;
    background-position: center center;
    height: 15rem;
    background-color: $color_base;
    margin-bottom: 5rem;
}

.promo {
    background-size: cover;
    background-position: top center;
    padding-top: 4rem;
    padding-bottom: 6rem;
    &__title,
    &__subtitle {
        color: $color_white
    }
    &__title {
        font-size: 2.8rem;
        line-height: 1.42;
        font-weight: bold;
        @media (min-width: $screen-md-min) {
            font-size: 3.6rem;
             line-height: 1.17;
        }
    }
    &__subtitle {
        font-size: 2.8rem;
        line-height: 1.2;
        margin-top: 2rem;
    }
}

.promo-splash {
    margin-top: 3rem;
    @media (min-width: $screen-md) {
        margin-top: 0;
    }
    &__header {
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
        background-color: $color_white;
        padding: 2rem;
    }
    &__body {
        padding: 2rem;
        background-color: rgba($color_white, .86);
        border-bottom-left-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }
    &__title {
        font-size: 3.6rem;
        font-weight: bold;
    }
    &__subtitle {
        font-size: 1.4rem;
        line-height: 1.3;
    }
    &__footer {
        text-align: center;
    }
    &__content {
        @media (min-width: $screen-md) {
            min-height: 25rem;
        }
        padding-bottom: 2rem;
    }
    &__list {
        li {
            position: relative;
            padding-left: 2rem;
            font-size: 1.6rem;
            line-height: 1.5;
            &:before {
                background-color: $color_link;
                content: "";
                top: .7rem;
                left: 0;
                width: .9rem;
                height: .9rem;
                border-radius: 50%;
                position: absolute;
                display: block;
            }
            & + li {
                margin-top: 1.2rem;
            }
        }
    }
    &__link {
        display: inline-block;
        margin-top: 1.5rem;
        font-size: 1.4rem;
    }
    &__button {
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}