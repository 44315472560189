.modal-open {
  overflow: hidden;
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(0,0,0,.5);
  &.in .modal-dialog {
    top: 0;
    @media (min-width: $screen-md-min) {
      transform: translateX(-50%) translateY(-50%);
      top: 50%;
    }
  }
}

.modal-dialog {
  border-radius: .4rem;
  transition: all .3s ease-out;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  padding: 2rem 1.8rem 4rem;
  @media (min-width: $screen-md-min) {
    width: auto;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -25%);
  }
  &__title {
    font-size: 2.4rem;
    line-height: 1.42;
  }
  &__text {
    font-size: 1.3rem;
    line-height: 1.38;
    margin-top: 1.8rem;
  }
}

.modal-button {
  display: inline-block;
  border-radius: .4rem;
  background-color: $color_link;
  color: $color_white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.4rem;
  padding: 2rem 3.5rem;
  border: none;
  box-shadow: none;
}

.modal-button-holder {
  margin-top: 4rem;
  text-align: right;
}

.modal-close {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 1rem;
  right: 1rem;
  background-image: url('../img/close.svg');
  background-size: cover;
}

.modal-login {
  @media (min-width: $screen-md-min) {
    max-width: 460px;
  }
}

.modal-link {
  margin-top: 2rem;
  display: inline-block;
  font-size: 1.3rem;
}

.modal-registration {
  @media (min-width: $screen-md-min) {
    width: 500px;
  }
}