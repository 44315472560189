.section {
    margin-top: $section_padding;
    margin-bottom: $section_padding;
}

.section-bg {
    padding-top: $section_padding;
    padding-bottom: $section_padding;
}

.section-btn {
    margin-top: 4rem;
}

.section-top {
    margin-bottom: 8rem;
}

.section-grey {
    background-color: $color_grey;
}

.section-merchant {
    color: $color_white;
    background-color: $color_base;
    background-image: url('../img/finger.jpg');
    background-position: center center;
    background-size: cover;
}