* {
    box-sizing: border-box;
}

html {
    font-size: 80%;
    @media (min-width: $screen-sm) {
        font-size: 62.5%;
    }
}

body {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: $color_white;
    color: $color_base;
    font-size: 1.6rem;
    line-height: 1.42;
}

a {
    color: inherit;
    text-decoration: none;
}