.map {
    background-color: $color_grey;
    padding-top: 2rem;
}

.map-content {
    @media (min-width: $screen-md-min) {
        border-bottom: 1px solid $color_link;
    }
}

.map-item {
    text-align: center;
    padding-bottom: 2rem;
    &__image {
        border: 1px solid transparent;
        height: 14.4rem;
    }
    &.active {
        .map-item__image {
            border: 1px solid $color_border_form;
            background-color: $color_white;
        }
        @media (min-width: $screen-md-min) {
            border-bottom: 5px solid $color_link;
        }
    } 
    &__title {
        margin-top: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $color_link;
        font-size: 1.4rem;
    }
    &__count {
        font-size: 1.3rem;
    }
}

.map-toggle {
    @media (min-width: $screen-md-min) {
        display: flex;
    }
    
    margin-bottom: 2.6rem;
    &__text {
        font-weight: bold;
    }
    &__radio {
        & + & {
            margin-top: 2rem;
        }
        @media (min-width: $screen-md-min) {
            & + & {
                margin-top: 0;
                margin-left: 4rem;
            }
        }
    }
}