/**
* Owl hack
*/
.owl-carousel .owl-wrapper-outer{
       overflow: hidden;
       position: relative;
       width: 100%;
       z-index: 1;  // added this
}
.owl-item { 
     transform: translateZ(0);
     -webkit-transform: translateZ(0); 
     -ms-transform: translateZ(0);
 }
 /***/

.owl-prev,
.owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    height: 3rem;
    background-size: cover;
    font-size: 0;
    display: none;
    @media (min-width: $screen-sm-min) {
        display: block;
    }
}

.owl-prev {
    left: 0;
    background-image: url('../img/left.svg');
}

.owl-next {
    right: 0;
    background-image: url('../img/right.svg');
}