.btn {
    display: block;
    font-size: 1.4rem;
    text-align: center;
    padding-top: 1.9rem;
    padding-bottom: 1.9rem;
    text-transform: uppercase;
    border: 1px solid $color_base;
    border-radius: .4rem;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    &--dark {
        background-color: $color_base;
        color: $color_link;
    }
    &--white {
        border-color: $color_white;
        color: $color_white;
    }
    &--green {
        color: $color_white;
        background-color: $color_link;
        border-color: $color_link;
    }
}