.services-group {
    margin-top: -2rem
}

.service {
    margin-top: 8rem;
    position: relative;
    &__title {
        font-size: 1.6rem;
        line-height: 1.25;
        text-transform: uppercase;
    }
    &__body {
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 1.5;
    }
    &__image {
        @media (min-width: $screen-md-min) {
            position: absolute;
            top: 0;
            left: -7rem
        }
    }
}