.affix {
    text-align: center;
    background-color: $color_link;
    font-size: 2rem;
    line-height: 1.2;
    &__title {
        color: $color_white;
    }
    &__body {
        margin-top: 2rem;
        color: $color_base;
    }
    &__raw {
        color: $color_base;
        margin-bottom: -4.5rem;
        @media (min-width: $screen-md-min) {
           margin-bottom: -2.5rem;
        }
    }
}

.affix-number {
    margin-top: 4rem;
    @media (min-width: $screen-md-min) {
        margin-top: 0
    }
    &__digits {
        color: $color_white;
        font-size: 72px;
        line-height: 1;
    }
    &__text {
        color: $color_white;
        font-size: 2.4rem;
        line-height: 1.25;
        margin-top: 1.7rem;
    }
}